@font-face {
  font-family: 'Samim';
  src: url('./fonts/Samim.woff2') format('woff2'),
       url('./fonts/Samim.woff') format('woff'),
       url('./fonts/Samim.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  unicode-range: U+0600-06FF; /* محدوده یونیکد فارسی */
}

@font-face {
  font-family: 'Samim';
  src: url('./fonts/Samim-Bold.woff2') format('woff2'),
       url('./fonts/Samim-Bold.woff') format('woff'),
       url('./fonts/Samim-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;
}

body {
  direction: rtl !important;
  font-family: "Samim", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #666;
}

.App {
  background-color: #fff; 
}

.bg-grad {
  background-color: transparent;
  background-image: linear-gradient(240deg, #3c4b64 0%, #5272aa 90%);
}

.Auth-form-content .btn-primary {
  background-color: #2C1C34;
  border: #2C1C34;
}

.offscreen {
  position: fixed;
  left: -9999px;
}

.errmsg {
  text-align: center;
  border-radius: 0.25rem;
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #fff;
  color: #000;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions>svg {
  margin-right: 0.25rem;
}

.Auth-form-content span.link-primary {
  cursor: pointer;
  color: #A254F0 !important;
}

.Auth-form-content span.link-primary:hover {
  color: #64358D !important;
}

a {
  text-decoration: none !important;
}

li {
  cursor: pointer;
}


nav a.active li {
  background-color: #7ebfee !important;
}

.desk-sidebar ul.inner-menu a.active li {
  color: #fff;
  background-color: transparent;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noSelect:focus {
  outline: none !important;
}

@media (max-width: 1024px) {

  .social-icons {
    bottom: -3%;
    background-color: #285AEB !important;
  }
}

/* Pagination */

.pagination-container li {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
  margin-right: 0.25rem;
  padding: 0 0.5rem;
  background-color: #5272aa;
  color: white;
  line-height: 1.9rem;
  border-radius: 0.125rem;
  transition-property: all;
  transition-duration: 100ms;
  user-select: none;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

@media (max-width: 640px) {
  .pagination-container li {
    padding: 0 0.5rem;
  }
}

.pagination-container li:hover {
  background-color: #1c41ba;
}

/* .pagination-container li:last-child {
  display: inline-flex;
  text-align: center;
  margin-left: 0;
  padding: 5px;
  border-left: none; 
}*/

.pagination-container li.active-page {
  background-color: rgb(1 31 75);
  color: white;
}

.hvh {
  max-height: 60vh!important;
}

.pagination-container li:last-child a,
.pagination-container li:first-child a {
  color: white;
}

/* Mobile menu */
#mobile-menu {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  overflow-x: auto !important;
  transition: 0.8s;
}

#mobile-menu .closebtn {
  position: absolute;
  top: 0;
  left: 25px;
  font-size: 36px;
  height: 50px;
}

.openbtn {
  cursor: pointer;
}

.openbtn:hover {
  background-color: #ECA902 !important;
}

/* SideBar Menu */
.desk-sidebar ul .inner-menu li:last-child {
  padding-bottom: 0 !important;
}

.desk-sidebar .inner-menu {
  transition: cubic-bezier(0.55, 0.085, 0.68, 0.53)
}

/* Gallery */

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
}

.galleryWrap .single {
  width: 200px;
  cursor: pointer;
}

.galleryWrap .single img {
  max-width: 100%;
}

.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #3c4b64b0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.bioWrap {
  position: fixed;
  top: 48%;
  left: 30%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #fbfbfbe7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 60%;
  border-radius: 30px;
  border: 2px solid #7ebfee;
  overflow: hidden;
}

.bio-holder-his .bioWrap {
  position: fixed;
  top: 59%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #fbfbfbe7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  height: 40%;
  border-radius: 6px;
  border: 2px solid #123b83cd;
  overflow: auto;
  background-color: #3c4b64;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext,
.bioWrap .btnClose {
  position: fixed;
  cursor: pointer;
  opacity: 1;
  color: #fff;
  font-size: 2rem;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 0.8;
}

.sliderWrap .btnClose,
.bioWrap .btnClose {
  top: 10px;
  left: 10px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.gallery-caption p span {
  font-family: 'Times New Roman', Times, serif;
  font-weight: 200;
  margin-right: 5px;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

/* Breadcrumbs */

.breadcrumbs {
  max-width: 1200px;
}

.breadcrumbs>* {
  display: inline-block;
  margin-left: 10px;
}

.breadcrumbs .crumb:after {
  color: white;
  content: '>';
  margin-right: 10px;
}

.breadcrumbs .crumb:last-child:after {
  display: none;
}

/* Header */

.main-header {
  height: 72px;
  border: none;
}

button.subBut {
  cursor: pointer !important;
}

/* Insta Logo */

.fill-gradient-instagram svg {
  background-clip: text;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;

  /* needed for browser support */
  -webkit-background-clip: text;
  /* see https://caniuse.com/#feat=background-img-opts */
  -webkit-text-fill-color: transparent;
  /* https://caniuse.com/#feat=text-stroke */
  padding: 2px;
  border-radius: 8px;
}

.fill-gradient-instagram-text svg {
  color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

/* Profile card */

.card-con {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card1 .card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  width: 900px;
  height: 480px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  background-color: #3c4b64;
  filter: blur(0.2px);
}

.card1 .left-container {
  flex: 1;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-left: 2px solid #7ebfee;
}

.card1 .right-container {
  flex: 1;
  max-width: 70%;
  height: 430px;
  padding: 10px;
  margin: 20px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1150px) {
  .card1 .card {
    flex-direction: column;
    margin: 10px;
    height: auto;
    width: 100%;
  }


  .card1 .left-container {
    flex: 1;
    max-width: 100%;
    border: none;
  }
}

@media only screen and (max-width: 600px) {
  .card1 .card {
    flex-direction: column;
    margin: 10px;
  }

  .card1 .left-container {
    flex: 1;
    max-width: 100%;
  }
}

.card1 img {
  border: double 5px transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, #7ebfee, #5272aa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 200px;
  height: 200px;
  max-width: 200px;
  margin-top: 70px;
}

.card1 h2 {
  font-size: 24px;
  margin-bottom: 5px;
}

.card1 h3 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 5px;
}

.gradienttext {
  background-image: linear-gradient(to right, white 10%, #7ebfee 100%);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icons a,
span.fill-gradient-instagram-text,
span.social-icon {
  background-image: linear-gradient(to right, rgb(1, 31, 75) 0%, #5272aa 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 30px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  transition: all 0.7s ease;
}

.social-icons a {
  width: 30px;
  height: 30px;
  font-size: 24px;
}

span.social-icon,
span.fill-gradient-instagram-text {
  width: 40px;
  height: 40px;
  font-size: 30px;
}

td.insta-holder-td span.fill-gradient-instagram-text {
  width: 15px;
  height: 15px;
  font-size: 20px;
}

.social-icons a.taken {
  /* background-image: none;
  background-color: white;
  color: #5272aa */
  display: none;
}

.social-icons a.takenInsta,
span.fill-gradient-instagram-text {
  background-clip: text;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
  /* needed for browser support */
  -webkit-background-clip: text;
  /* see https://caniuse.com/#feat=background-img-opts */
  -webkit-text-fill-color: transparent;
}

.social-icons a.fill-gradient-instagram-text:hover {
  background-clip: text;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%) !important;
  /* needed for browser support */
  -webkit-background-clip: text;
  /* see https://caniuse.com/#feat=background-img-opts */
  -webkit-text-fill-color: transparent;
}

.card1 p {
  font-size: 18px;
  margin-bottom: 20px;
}

.card1 table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-collapse: collapse;
}

.card1 td {
  padding: 10px;
  border: none;
  border-radius: 20px;
  color: white;
}

.card1 td:first-child {
  font-weight: bold;
}

.credit a {
  text-decoration: none;
  color: #fff;
  font-weight: 800;
}

.credit {
  color: #fff;
  text-align: center;
  margin-top: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* result table */

.table-responsive table.table thead th {
  background-color: rgb(60 75 100) !important;
  color: #fff;
}

.table-responsive table.table {
  border-color: rgb(60 75 100) !important;
}

.table-responsive table.table tbody tr:nth-child(even) td {
  background-color: rgb(229 231 235) !important;
}

@media (max-width: 767px) {
  .mobVis {
    visibility: visible;
    opacity: 1;
  }

  .mobHid {
    visibility: hidden;
    opacity: 0;
  }
}

.inq-result div.inq-holder,
.sub-num div.inq-holder {
  margin: 0 auto !important;
}

@media (max-width: 767px) {
  .recharts-default-legend {
    display: none;
  }
}

/*scrollbar design*/

.scroll-design::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.scroll-design::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgb(60 75 100);
}

.scroll-design::-webkit-scrollbar {
  width: 0.6em;
}

.scroll-hidden::-webkit-scrollbar {
  display: none !important;
}

body::-webkit-scrollbar {
  display: none !important;
}

body {
  overflow: hidden !important;
}

.scroll-hidden {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}

.inner-scroll {
  height: calc(100vh - 210px);
  max-height: fit-content;
}

.inner-scroll-250 {
  height: calc(100vh - 250px);
  max-height: fit-content;
}

.inner-scroll::-webkit-scrollbar-track,
.inner-scroll-250::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.inner-scroll::-webkit-scrollbar-thumb,
.inner-scroll-250::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgb(60 75 100);
}

.inner-scroll::-webkit-scrollbar {
  width: 0.3em;
  /* display: none; */
}

.inner-scroll-250::-webkit-scrollbar {
  width: 0.6em;
}

/*sidebar scrollbar design*/

.scroll-side::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid #cacaca;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.scroll-side::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #5272aa;
}

.scroll-side::-webkit-scrollbar {
  width: 0.6em;
}

/*counter holders*/

.button-holder .counter-circle {
  padding: 0.1rem 0.2rem;
  padding-top: 5px;
  border-radius: 35px;
  text-align: center;
  display: inline-block;
}

/*Type Writer*/

.Typewriter__wrapper{
  font-size: 40px;
  text-align: left;
}

/*ّFlags*/

.flag-holder > div > span > img{
  height: 16px;
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 768px) {
  /* تنظیمات عمومی برای رابط کاربری */
  body {
    font-size: 12px; /* اندازه متن را برای موبایل کوچک کنید */
  }

  .galleryWrap {
    flex-direction: column; /* چیدمان گالری را عمودی کنید */
    max-width: 100%; /* اجازه دهید گالری تمام عرض صفحه را بگیرد */
  }

  .card1 .card {
    flex-direction: column; /* تغییر چینش کارت به عمودی */
    margin: 10px; /* حاشیه کارت‌ها */
    height: auto; /* ارتفاع خودکار */
    padding: 10px; /* padding داخلی */
  }

  .card1 img {
    width: 150px; /* کاهش اندازه تصویر در کارت‌ها */
    height: 150px;
  }

  /* تنظیمات دکمه‌ها */
  .btnPrimary {
    padding: 10px; /* فضای داخلی بیشتر */
    width: 100%; /* دکمه‌ها تمام عرض را بگیرند */
  }

  /* سایر تنظیمات */
  .social-icons {
    flex-direction: column; /* چینش نمادهای اجتماعی به صورت ستونی */
  }
}

/* برای صفحه نمایش‌های کوچکتر */
@media (max-width: 480px) {
  /* حاشیه‌ها و فونت‌ها */
  body {
    font-size: 10px; /* اندازه متن برای صفحه‌های کوچک‌تر */
  }

  .card1 h2, .card1 h3 {
    font-size: 20px; /* اندازه عنوان‌ها را کاهش دهید */
  }

  .card1 p {
    font-size: 16px; /* متن کارت را کوچکتر کنید */
  }

  /* سایر تنظیمات */
  .bioWrap {
    width: 90%; /* بیوگرافی را کوچک کنید */
    height: auto; /* ارتفاع خودکار */
  }

  .social-icons a {
    width: 40px;
    height: 40px;
    font-size: 20px; /* اندازه آیکون‌های اجتماعی را کوچک کنید */
  }
}

/* برای تنظیم متن متحرک */
.Typewriter__wrapper {
  font-size: 300px; /* اندازه متن تایپ متحرک */
  text-align: center; /* تراز کردن متن */
}

/* Dark Mode Styles
body.dark {
  background-color: #121212; 
}

input, textarea, select {
  background-color: black;
  color: white;
  border: 1px solid gray;
}

input::placeholder, textarea::placeholder {
  color: lightgray; 
}

.dark.button {
  background-color: #2C1C34;
  color: white; 
}

.darkbutton:hover {
  background-color: #3B3B3B;
} */
.horizontal-scroll {
  display: flex; /* این ویژگی باعث می‌شود که المان‌های داخلی در یک خط قرار بگیرند */
  overflow-x: auto; /* اجازه اسکرول عرضی را می‌دهد */
  white-space: nowrap; /* جلوگیری از برش خوردن خطوط */
  -webkit-overflow-scrolling: touch; /* برای داشتن اسکرول نرم در دستگاه‌های iOS */
}

/* المان‌های داخلی (مانند کارت‌ها یا لیست‌ها) */
.scroll-item {
  min-width: 150px; /* عرض حداقلی برای هر مورد */
  margin-right: 10px; /* فاصله بین المان‌ها */
}
@media (max-width: 768px) {
  .horizontal-scroll {
      overflow-x: auto; 
  }
}