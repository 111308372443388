
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.575); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it covers other content */
}

.modal-content {
  background: white;
  padding: 40px; /* Increased padding for more space around the content */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 400px;
  max-width: 500px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px; /* Set a fixed width */
  height: 30px; /* Set a fixed height */
  border: none; /* Remove border */
  background-color: red; /* Red background */
  color: white; /* White text */
  border-radius: 50%; /* Make it circular */
  cursor: pointer; /* Change cursor on hover */
  display: flex;
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
  font-size: 1.2em; /* Adjust font size */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.close-button:hover {
  background-color: darkred; /* Darker red on hover for visual feedback */
}
.modal-content h3 {
  margin-top: 0; /* Remove default margin from the top */
  margin-bottom: 20px; /* Space between the heading and the inputs */
  font-size: 1.5em; /* Increase the font size of the heading */
  color: #333; /* Darkish color for better contrast */
  text-align: center; /* Center the heading text */
  
}

.modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content button:hover {
  background-color: #218838;
}
.modal-content select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content input, select {
  color: #000; /* Make sure text is not the same as background */
  background-color: #fff; /* White background */
  border: 1px solid #ccc;
  padding: 10px;
  width: 100%;
}
.modal-content p{
  margin-top: 0; /* Remove default margin from the top */
  margin-bottom: 20px; /* Space between the heading and the inputs */
  color: #333; /* Darkish color for better contrast */
  text-align: right; /* Center the heading text */
}