/* Container: برای قرار دادن کل المان‌ها در مرکز صفحه و تنظیم حاشیه‌ها */
/* .container {
  position: relative;
 width: 100%;
  border-radius: 500px; 
  padding: 8px; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  z-index: 0;
} */

/* Search Container: برای طراحی بخش جستجو */
.search-container {
  margin-bottom: 1px;
  position: relative;
  margin-left: 10px;
  border-radius: 50px;
  background: linear-gradient(135deg, rgb(82,114,170) 0%, rgb(63,80,109) 100%);
  padding: 0.5px; /* فضای داخلی کم */
}

/* اضافه کردن گوشه‌های گرد با سایه‌ها */
.search-container::after, .search-container::before {
  content: "";
  width: 80%;
  height: 80%;
  border-radius: inherit; /* استفاده از همان گوشه‌های گرد */
  position: absolute;
}

.search-container::before {
  top: -1px;
  left: -1px;
  background: linear-gradient(0deg, rgb(63,80,109) 0%, rgb(63,80,109) 100%);
  z-index: -1;
}

.search-container::after {
  bottom: -1px;
  right: -1px;
  background: linear-gradient(0deg, rgb(63,80,109) 0%, rgb(63,80,109) 100%);
  box-shadow: rgba(79, 156, 232, 0.7) 3px 3px 5px 0px, rgba(79, 156, 232, 0.7) 5px 5px 20px 0px;
  z-index: -2;
}

/* Input field: تنظیم عرض، فضای داخلی و اندازه فونت */
.input {
  padding: 5px; /* فضای داخلی کمتر */
  background: linear-gradient(135deg, rgb(63,80,109) 0%, rgb(63,80,109) 100%);
  border: none;
  color: #202021;
  font-size: 16px; /* فونت کوچکتر برای فضای کمتر */
  border-radius: 50px;
}

/* حالت focus برای ورودی */
.input:focus {
  outline: none;
  background: linear-gradient(135deg, rgb(239, 247, 255) 0%, rgb(214, 229, 247) 100%);
}

/* Search icon: طراحی آیکون جستجو */
.search__icon {
  width: 30px; /* کمی کوچکتر کردن آیکون */
  aspect-ratio: 1;
  border-left: 2px solid white;
  border-top: 5px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 60%;
  padding-left: 10px;
  margin-right: 2px;
}

/* تغییر رنگ آیکون هنگام hover */
.search__icon:hover {
  border-left: 3px solid white;
}

.search__icon path {
  fill: white;
}
/* تغییر رنگ آیکون هنگام hover */