
.container {
    min-height: 10vh;
    width: 100%;
    display: flex; /* Use flexbox for centering */
    align-items: center;
    justify-content: center;
    flex-direction: column; /* Stack flex children vertically */
}

.card {
    width: 500px;
    height: 300px;
    /* color: #fff; */
    cursor: pointer;
    perspective: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.front {
    width: 100%;
    height: 100%;
    /* background-image: linear-gradient(45deg, #000000, #191b1f); */
    position: relative; /* Change to relative for positioning logo */
    padding: 20px 30px;
    border-radius: 20px;
    overflow: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column; /* Stack contents vertically */
    justify-content: space-between; /* Space out elements */
    margin-top: 20px; /* Space between the input and card */
    /* background-image: url('../img/pasaragad.jpg'); */
    /* background-image: url('../img/`[prefix]`'); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5%;
    /* background-image: url('image1.png'), url('image2.png'); */
    background-position: 0px -20px;
    
}

.row {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.bank-icon {
    position: fixed; /* Position the icon absolutely */
    top: 27px; /* Distance from top */
    left: 180px; /* Distance from right */
    height: 70px; /* Set a fixed height for better alignment */
}

.map-img {
    width: 4cm;
    position: absolute;
    top: -15px;
    left: 10px; 
    
    
   
}

.card-no {
    font-size: 40px; /* Updated for better visibility */
    margin-top: 35px;
    text-align: center; /* Center the formatted card number */
}

.card-holder {
    font-size: 25px;
    text-align: right; /* Center the holder */
    font-weight: bold;
    margin-top: auto;
    
}

.name {
    font-size: 22px;
    text-align: center; /* Center name */
    margin-top: -30px;
    font-weight: bold;
}

.error-message {
    font-size: 14px;
    color: red; 
    text-align: center; 
    margin-top: 10px; 
}

.input-card {
    width: 120px;
    height: 40px; /* عرض ورودی‌ها کم‌تر از مقدار قبلی */
    margin: 0 4px; /* فاصله کوچک بین ورودی‌ها */
    text-align: center; /* تنظیم متن به وسط ورودی */
}

.input-container {
    display: flex; /* استفاده از flex برای چینش */
    justify-content: flex-start; /* شروع از سمت چپ */
    margin-bottom: 20px; /* فاصله زیر ورودی‌ها */
}

.submit-button {
    margin-left: 10px; /* فاصله بین ورودی‌ها و دکمه */
    padding: 8px 12px; /* تنظیم padding برای دکمه */
    font-size: 14px; /* اندازه متن دکمه */
}