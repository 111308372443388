.pagination-container {
    display: flex;
    justify-content: center; /* Center pagination */
    align-items: center; /* Vertical alignment */
    gap: 1px; /* Space between buttons */
    margin: 0;
    max-width: 100%;
    flex-wrap: nowrap; /* Keep in a single line */
    padding: 0;
    color: #fff;
    border-radius: 100%;
}

.pagination-container .page-link {
    width: 30px; /* Adjust width for smaller buttons */
    height: 30px; /* Match height with width */
    display: flex;
    justify-content: center; /* Center content */
    align-items: center; /* Center content */
    border-radius: 100%; /* Circular buttons */
    background-color: transparent; /* No background */
    color: #004a80; /* Adjusted color */
    text-align: center;
    border: none; /* No border */
    margin: 0; /* No extra margin */
    padding: 0; /* No0 padding */
    box-sizing: border-box; /* Include padding/borders in width/height */
    transition: background-color 0.3s, color 0.3s;
    font-size: 12px; /* Adjust font size */
    color: #fff;
    border-radius: 50px;
}

.pagination-container .active {
    background-color: #004a80; /* Active background color */
    color: #fff; /* Active text color */
    border: 2px solid #007bbf; /* Optional border for more emphasis */
    
}

.pagination-container .page-link:hover {
    background-color: #007bbf; /* Hover effect */
    color: #fff; /* Change text color on hover */
}

.pagination-container .disabled {
    opacity: 0.5; /* Lower visibility for disabled state */
    cursor: not-allowed; /* Not-allowed cursor */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .pagination-container .page-link .breakLabel{
        width: 10px; /* Smaller size for mobile */
        height: 10px; /* Match height with width */
        font-size: 10px; /* Smaller font size */
        border-radius: 100px;
    }
    
    .pagination-container .active {
        border: none; /* Remove border on mobile */
    }
}
.pagination-footer {
    position: fixed; /* قرار دادن در موقعیت ثابت */
    bottom: 0; /* در انتهای صفحه */
    left: 0; /* در سمت چپ */
    right: 0; /* در سمت راست */
    height: 10px; /* ارتفاع فوتر، می‌توانید بر اساس نیاز تغییر دهید */
    background-color: rgba(255, 255, 255, 0.9); /* رنگ پس‌زمینه با شفافیت */
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.1); /* کمی سایه برای جدا کردن از محتوا */
    z-index: 1; /* اطمینان از اینکه بالای سایر المان‌ها قرار می‌گیرد */
    display: flex;
    justify-content: center; /* تنظیم المان‌ها در مرکز */
    align-items: center; /* عمودی تراز کردن */
    border-radius: 60px;
}

/* استایل برای دیوایس موبایل */
@media (max-width:1080px) { /* تغییر max-width به 698px */
    .pagination-footer {
        height: 60px; /* ارتفاع فوتر برای موبایل */
        font-size: 10px; /* اندازه فونت برای موبایل */
    }
}

/* استایل برای دیوایس کامپیوتر */
@media (min-width: 1081px) { /* تغییر min-width به 699px */
    .pagination-footer {
        height: 60px; /* ارتفاع فوتر برای کامپیوتر */
        font-size: 16px; /* اندازه فونت برای کامپیوتر */
    }
}