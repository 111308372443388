li {
      justify-content: space-between;
      text-align: justify; /* متن را به صورت یکنواخت در باکس پخش می‌کند */
      box-sizing: border-box;
      overflow: hidden; /* از خروج متن از باکس جلوگیری می‌کند */
      max-height: 100%; /* از ارتفاع حداکثری اطمینان حاصل می‌کند */
  }
  
  li p {
      overflow-wrap: break-word; /* کلمات طولانی را بشکند */
      word-wrap: break-word; /* پشتیبانی از مرورگرهای قدیمی‌تر */
      text-overflow: ellipsis; /* سه نقطه برای متن طولانی */
      white-space: normal; /* از قرار گرفتن متن در یک خط جلوگیری می‌کند */
      line-height: 1.5; /* فاصله بین خطوط */
      font-size: 14px; /* اندازه متن مناسب برای صفحات کوچک */
  }
  
  li h6 {
      margin-bottom: 0.5rem;
      font-size: 16px; /* اندازه تیتر */
      line-height: 1.4;
  }
  
  @media (max-width: 768px) {
      li {
          font-size: 12px; /* اندازه کوچک‌تر برای صفحات موبایل */
          padding: 0.5rem;
      }
  
      li h6 {
          font-size: 14px;
      }
  
      li p {
          font-size: 12px;
      }
  }
  